<template>
  <div class="home white--text">
    <h1 class="mb-5">Online-Tool zur Beleuchtung in Büroräumen</h1>
    <p>
      Herzlich willkommen bei Beleuchtungstool.de. Mit dieser Web App können Sie prüfen, wie Sie die Beleuchtung in Ihrem Büro menschzentriert und energieeffizient optimieren können.
    </p>
    <p>Beginnen Sie mit der Auswahl Ihrer Ausgangssituation:</p>
    <p><v-btn 
      block
      left
      class="c-btn"
      to="/eingA1">
        Beleuchtungssituation von neuen Räumen planen.
    </v-btn></p>
    <p><v-btn
      block
      left
      class="c-btn"
      to="/eingA2">
        Zur Beleuchtungssituation in Büroräumen informieren, ohne bereits konkrete Raum- und Beleuchtungsparameter zu kennen.
    </v-btn></p>
    <p><v-btn
      block
      left
      class="c-btn"
      to="/eingA3">
        Beleuchtungssituation bestehender Büroräume optimieren.
    </v-btn></p>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
  }
}
</script>

<style>
h1 {
  font-weight: normal;
}

.c-btn {
  text-transform: none;
  font-size: 1em !important;
  max-width: 600px !important;
  margin-top: 25px;
  height: auto !important;
  padding: 15px !important;
  display: block !important;
  white-space: normal !important;
  text-align: left !important;
  justify-content: normal !important;
}

</style>